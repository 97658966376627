import { RouteRecordRaw } from 'vue-router';


/** @ignore */
const routes: Array<RouteRecordRaw> = [

  {
    path: '/backend/vendors',
    name: 'backend-vendors',
    component: () => import ('../../views/backend/vendors/vendor-overview.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/backend/vendors/:category',
    component: () => import ('../../views/backend/vendors/vendor-manager.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/backend/vendors/:category/:vendor_id',
    component: () => import ('../../views/backend/vendors/vendor-profile.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/backend/vendors/:category/:vendor_id/reports',
    component: () => import ('../../views/backend/vendors/vendor-reports.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/backend/vendors/:category/:vendor_id/bookings',
    component: () => import ('../../views/backend/vendors/bookings/booking-manager.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/backend/vendors/:category/:vendor_id/bookings/:booking_id',
    component: () => import ('../../views/backend/vendors/bookings/booking-view.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/backend/vendors/:category/:vendor_id/bookings/:booking_id/reschedule',
    component: () => import ('../../views/backend/vendors/bookings/booking-reschedule.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/backend/vendors/:category/:vendor_id/bookings/:booking_id/cancel',
    component: () => import ('../../views/backend/vendors/bookings/booking-cancel.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/backend/vendors/:category/:vendor_id/bookings/:booking_id/refund',
    component: () => import ('../../views/backend/vendors/bookings/booking-refund.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/backend/vendors/:category/:vendor_id/bookings/:booking_id/success',
    component: () => import ('../../views/backend/vendors/bookings/booking-success.vue'),
    meta: {
      requiresAuth: true,
    }
  },

  {
    path: '/backend/vendors/supplier/:vendor_id',
    component: () => import ('../../views/backend/vendors/supplier/supplier-profile.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/backend/vendors/supplier/:vendor_id/items',
    component: () => import ('../../views/backend/vendors/supplier/supplier-items.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/backend/vendors/supplier/:vendor_id/items/:id',
    component: () => import ('../../views/backend/vendors/supplier/supplier-item.vue'),
    meta: {
      requiresAuth: true
    }
  },

];

export default routes;
