import { RouteRecordRaw } from 'vue-router';


/** @ignore */
const routes: Array<RouteRecordRaw> = [

  {
    path: '/app/services',
    name: 'services',
    component: () => import ('../views/services/bookings/booking-manager.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/services/overview',
    component: () => import ('../views/services/services-overview.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/services/:category',
    component: () => import ('../views/services/services-search.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/services/:category/bookings/:booking_id',
    component: () => import ('../views/services/bookings/booking-view.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/services/:category/bookings/:booking_id/reschedule',
    component: () => import ('../views/services/bookings/booking-reschedule.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/services/:category/bookings/:booking_id/payment',
    component: () => import ('../views/services/bookings/booking-payment.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/services/:category/bookings/:booking_id/cancel',
    component: () => import ('../views/services/bookings/booking-cancel.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/services/:category/bookings/:booking_id/success',
    component: () => import ('../views/services/bookings/booking-success.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/services/:category/:profile_id',
    component: () => import ('../views/services/services-profile.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/services/:category/:profile_id/schedule',
    component: () => import ('../views/services/bookings/booking-schedule.vue'),
    meta: {
      requiresAuth: true,
    }
  },

];

export default routes;
