import { RouteRecordRaw } from 'vue-router';


/** @ignore */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/backend/auth/sign-in',
    component: () => import ('../views/backend/auth/sign-in.vue'),
    meta: {
      requiresAdmin: true
    }
  },

  {
    path: '/backend/auth/forgot-password',
    component: () => import ('../views/backend/auth/forgot-password.vue'),
    meta: {
      requiresAdmin: true
    }
  },
];

export default routes;
