import { RouteRecordRaw } from 'vue-router';


/** @ignore */
const routes: Array<RouteRecordRaw> = [

  {
    path: '/app/vendor/supplier',
    name: 'vendor-supplier',
    component: () => import ('../../views/vendor/supplier/supplier-profile.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/supplier/items',
    component: () => import ('../../views/vendor/supplier/supplier-items.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/supplier/items/add',
    component: () => import ('../../views/vendor/supplier/supplier-item.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/supplier/items/:id',
    component: () => import ('../../views/vendor/supplier/supplier-item.vue'),
    meta: {
      requiresAuth: true,
    }
  },

];

export default routes;
