import { addDoc, getFirestore, collection, getDocs, where, query, orderBy, limit, doc, startAfter, startAt, endAt, CollectionReference, Firestore, DocumentReference, WhereFilterOp, QuerySnapshot, QueryDocumentSnapshot, QueryConstraint, writeBatch, updateDoc, serverTimestamp, documentId, setDoc, arrayUnion, FieldPath } from "@firebase/firestore";
import * as geofire from 'geofire-common';

/**
 * The where caluse array, to prevent using the wrong arguments in where caluses.
 *
 * @interface WhereClauseArray
 */
interface WhereClauseArray {
  /**
   * The first index targets the name of the property we want to compare to.
   * 
   * @type {string|FieldPath}
   * @memberof WhereClauseArray
   */
  0: string|FieldPath;

  /**
   * The second index is a whereFilterInterface.
   *
   * @type {WhereFilterOp}
   * @memberof WhereClauseArray
   */
  1: WhereFilterOp;

  /**
   * The third index gives a small list of possible values to compare to the database records.
   *
   * @type {(string|number|boolean)}
   * @memberof WhereClauseArray
   */
  2: string|string[]|number|boolean|Date
}

/**
 * A wrapper interface to add an id and distance to geo search documents
 *
 * @interface GeoSearchResult
 */
interface GeoSearchResult {
  /**
   * Document id
   *
   * @type {string}
   * @memberof GeoSearchResult
   */
  id?: string;

  /**
   * The distance from the geo location given
   *
   * @type {number}
   * @memberof GeoSearchResult
   */
  distance?: number;
}

/**
 * The Database is a layer between us and firebase to decouple the database and also remove repetitive
 * code to interact with the backend.
 *
 * @export
 * @class Database
 */
export default class Database {

  /**
   * The database instance from Firebase
   *
   * @private
   * @type {Firestore}
   * @memberof Database
   */
  private _db:Firestore;
  /**
   * The last item returned from pagination
   *
   * @private
   * @type {(QueryDocumentSnapshot | undefined)}
   * @memberof Database
   */
  private _paginateLastDoc: QueryDocumentSnapshot | undefined;
  /**
   * Pagination default size
   *
   * @memberof Database
   */
  public paginateSize = 15;
  /**
   * Order by condition allows us to sort the data by a given condition.
   *
   * @type {(string|null)}
   * @memberof Database
   */
  public orderByCondition:string|null = null;
  /**
   * Direction of the orderBy condition
   *
   * @type {('desc' | 'asc')}
   * @memberof Database
   */
  public orderByConditionDirection: 'desc' | 'asc' = 'desc';
  /**
   * The limit condition allows us to limit how many records to return from the batabase
   *
   * @type {(number|null)}
   * @memberof Database
   */
  public limitCondition:number|null = null;
  /**
   * The where conditions variable stores all our clauses for the queries.
   *
   * @private
   * @type {Array<QueryConstraint>}
   * @memberof Database
   */
  private _whereConditions:Array<QueryConstraint> = [];

  /**
   * Creates an instance of Database.
   * We also create a new private property to store the collection name we're targeting.
   * 
   * @param {string} _collectionName
   * @memberof Database
   */
  constructor(private _collectionName: string) {
    this._db = getFirestore();

    return this;
  }

  /**
   * Fetches the collection reference from the collection name
   *
   * @return {*}  {CollectionReference}
   * @memberof Database
   */
  getCollectionRef(): CollectionReference {
    return collection(this._db, this._collectionName);
  }

  /**
   * Fetches the document reference from the collection name and document id
   *
   * @param {string} id
   * @return {*}  {DocumentReference}
   * @memberof Database
   */
  getDocumentRef(id:string): DocumentReference {
    return doc(this._db, this._collectionName, id);
  }
  
  /**
   *=============================
   * Read documents section
   *=============================
   */
  /**
   * Gets a single document from the database from it's id
   *
   * @param {string} id
   * @return {*}  {Promise<QuerySnapshot>}
   * @memberof Database
   */
  async getDocument(id: string): Promise<QuerySnapshot> {
    try {
      this._whereConditions.push(
        where(documentId(), '==', id)
      );

      return await this.getDocuments();
    } catch(err) {
      throw new Error("Failed to fetch the document.");
    }
  }

  /**
   * Gets a single document by where conditions and also makes sure there is no deleted documents returns as well.
   *
   * @return {*}  {Promise<QuerySnapshot>}
   * @memberof Database
   */
  async getDocumentBy(): Promise<QuerySnapshot> {
    try {
      const qOptions = [
        ...this._whereConditions,
        where('_deleted', '==', false),
        limit(1)
      ];

      if(this.orderByCondition !== null) {
        qOptions.push(orderBy(this.orderByCondition, this.orderByConditionDirection));
      }

      const q = query(this.getCollectionRef(), ...qOptions);

      return await getDocs(q);
    } catch(err) {
      throw new Error("Failed to get document. ");
    }
  }

  /**
   * Gets many documents under the same where conditions and also makes sure there is no deleted
   * documents returns as well.
   *
   * @return {*}  {Promise<QuerySnapshot>}
   * @memberof Database
   */
  async getDocuments(): Promise<QuerySnapshot> {
    try {
      const qOptions = [
        ...this._whereConditions,
        where('_deleted', '==', false),
      ];

      if(this.limitCondition !== null) {
        qOptions.push(limit(this.limitCondition));
      }

      if(this.orderByCondition !== null) {
        qOptions.push(orderBy(this.orderByCondition, this.orderByConditionDirection));
      }

      const q = query(this.getCollectionRef(), ...qOptions);
      
      return await getDocs(q);
    } catch(err) {
      throw new Error('Failed to fetch documents.'+err);
    }
  }

  /**
   * The where method builds the whereConditions property to be correctly formatted for the Firestore system.
   *
   * @param {WhereClauseArray[]} allInputWhereClauses
   * @return {*}  {this}
   * @memberof Database
   */
  where(allInputWhereClauses:WhereClauseArray[]): this {
    const localWhereConditions:any = [];

    allInputWhereClauses.forEach(whereClause => {
      localWhereConditions.push(where(whereClause[0], whereClause[1], whereClause[2]));
    });

    this._whereConditions = localWhereConditions;

    return this;
  }

  /**
   * The orderBy method sets the orderBy property
   *
   * @param {any} value
   * @return {*}  {this}
   * @memberof Database
   */
  orderBy(value: any, direction: 'desc' | 'asc' = 'desc'): this {
    this.orderByCondition = value;
    this.orderByConditionDirection = direction;

    return this;
  }

  /**
   * The limit method sets a new limit.
   *
   * @param {number} value
   * @return {*}  {this}
   * @memberof Database
   */
  limit(value:number): this {
    this.limitCondition = value?? this.limitCondition;

    return this;
  }
  
  /**
   * THe paginations method fetches documents with the default limit or a new limit passed by parameter.
   * Once the methods is called for the first time, you can recall the same method on the Database to paginate forward.
   *
   * @param {number} [size]
   * @return {*}  {Promise<QuerySnapshot>}
   * @memberof Database
   */
  async paginate(size?: number, reset?: boolean): Promise<QuerySnapshot> {
    this.paginateSize = size ?? this.paginateSize;

    const qOptions = [
            ...this._whereConditions,
            where('_deleted', '==', false),
            limit(this.paginateSize)
          ];

    if(this.orderByCondition !== null) {
      qOptions.push(orderBy(this.orderByCondition, this.orderByConditionDirection));
    }
    
    if(this._paginateLastDoc !== undefined && reset !== true) {
      qOptions.push(startAfter(this._paginateLastDoc));
    }

    const result = await getDocs(query(this.getCollectionRef(), ...qOptions));

    this._paginateLastDoc = result.docs[result.docs.length-1];

    return result;
  }

  /**
   *=============================
   * Update document section
   *=============================
   */
  /**
   * Update document format changes the update object to perform ether a normal update or an targeted arrayUnion.
   *
   * @private
   * @param {*} updateWith
   * @param {string} [arrayTarget]
   * @return {*} 
   * @memberof Database
   */
  private updateDocumentFormat(updateWith: any, arrayTarget?: string) {
    return Object.assign(
      {},
      { modified_at: serverTimestamp() },
      arrayTarget !== undefined? { [arrayTarget]: arrayUnion(updateWith) }: { ...updateWith }
    );
  }
  
  /**
   * Update document method will update a document referrence by it's id, but this method also allows to target
   * an array for arrayUnion updates.
   * 
   * In the event that the document does not exist in the database, the methods will create the document for you.
   *
   * @param {string} id
   * @param {*} updateWith
   * @param {string} [arrayTarget]
   * @return {*}  {Promise<any>}
   * @memberof Database
   */
  async updateDocument(id: string, updateWith: any, arrayTarget?: string): Promise<any> {
    try {
      const documentRef = await this.getDocument(id);

      if(documentRef.empty) {
        if(updateWith._deleted !== undefined) {
          return false;
        }

        return this.createDocument(updateWith, id);
      }

      const updateDocument = this.updateDocumentFormat(updateWith, arrayTarget);

      return await updateDoc(documentRef.docs[0].ref, updateDocument);
    } catch(err) {
      throw new Error("Could not update the document"+err);
    }
  }

  /**
   * The batch method will perform a update on all documents with the same where conditions, so updating
   * multiple documents at the same with the firestore batch method to prevent overlap.
   *
   * @param {object} updateWith
   * @return {*} 
   * @memberof Database
   */
  async batch(updateWith:object) {
    try {
      const transaction = writeBatch(this._db),
            documents:QuerySnapshot = await this.getDocuments();

      if(documents.size <= 0) {
        throw new Error('No documents gound');
      }

      const updateDocument = {
        ...updateWith,
        modified_at: serverTimestamp()
      };

      documents.forEach((document) => {
        transaction.update(document.ref, updateDocument);
      });

      return await transaction.commit();
    } catch(err) {
      throw new Error("fail to perform batch update."+err);
    }
  }

  /**
   *=============================
   * Create document section
   *=============================
   */

  /**
  * The create document method will create a new document and also set it's deleted status, created_at and
  * modified_at timestamps.
  *
  * @param {object} createWith
  * @param {string} [id]
  * @return {*}  {Promise<any>}
  * @memberof Database
  */
  async createDocument(createWith: object, id?: string): Promise<any> {
    try {
      const newDocument = {
        ...createWith,
        _deleted: false,
        modified_at: serverTimestamp(),
        created_at: serverTimestamp()
      };

      return id? await setDoc(this.getDocumentRef(id), newDocument): await addDoc(this.getCollectionRef(), newDocument);
    } catch(err) {
      throw new Error("Failed to create a new document."+err);
    }
  }

  /**
  *=============================
  * Delete document section
  *=============================
  */

  /**
   * The deleteme document method will delete a document by setting it's _deleted status to true and
   * updating it's modified_at timestamp.
   * 
   * All records in the system will not be deleted. simply hidden from view.
   *
   * @param {string} id
   * @return {*} 
   * @memberof Database
   */
  async deleteDocument(id: string) {
    return await this.updateDocument(id, {
      _deleted: true,
      modified_at: serverTimestamp()
    });
  }

  /**
   * The delete batch methods performce the same update to the deleted status but uses the batching method
   * to delete multiple documents.
   *
   * @return {*} 
   * @memberof Database
   */
  async deleteBatch() {
    return await this.batch({
      _deleted: true,
      modified_at: serverTimestamp()
    });
  }

  /**
  *=============================
  * Delete document section
  *=============================
  */

  /**
   * Geo search for documents within a radius
   *
   * @template T
   * @param {number[]} location
   * @param {number} [radius=5]
   * @param {string} [geohash='geohash']
   * @param {string} [coord='coord']
   * @return {*}  {Promise<T[]>}
   * @memberof Database
   */
  async geoSearch<T extends GeoSearchResult>(location: number[], geohash = 'geohash', coord='coord', radius = 5): Promise<T[]> {
    const radiusInMeters = radius*1000,
          bounds = geofire.geohashQueryBounds(location, radiusInMeters),
          promises = [];

    for(const bound of bounds) {
      const queryConditons: any[] = [
        orderBy(geohash),
        startAt(bound[0]),
        endAt(bound[1])
      ];

      if(this._whereConditions.length) {
        queryConditons.push(...this._whereConditions);
      }

      const q = query(this.getCollectionRef(), ...queryConditons);

      promises.push(getDocs(q));
    }

    return Promise.all(promises).then(snapshots => {
      const documentsInRange: T[] = [];

      for(const snap of snapshots) {
        for(const document of snap.docs) {
          const loc = [document.get(`${coord}.lat`), document.get(`${coord}.lng`)];
          const distanceInKilometers = geofire.distanceBetween(loc, location),
                distanceInMeters = distanceInKilometers * 1000;

          if(distanceInMeters <= radiusInMeters) {
            const data:any = {
              id: document.id,
              distance: distanceInMeters,
              ...document.data()
            };

            documentsInRange.push(data);
          }
        }
      }

      return documentsInRange;
    });
  }
}
