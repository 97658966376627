import { RouteRecordRaw } from 'vue-router';


/** @ignore */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/app/veterinaries',
    name: 'veterinaries',
    component: () => import ('../views/veterinaries/veterinaries.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/veterinary/:id',
    name: 'veterinary',
    component: () => import ('../views/veterinaries/veterinary.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/veterinary/:id/reviews',
    component: () => import ('../views/veterinaries/veterinary-reviews.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/veterinary/:id/schedule',
    component: () => import ('../views/veterinaries/bookings/booking-schedule.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/veterinary/success',
    component: () => import ('../views/veterinaries/bookings/booking-success.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/booking-manager',
    component: () => import ('../views/veterinaries/bookings/booking-manager.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/booking/:id',
    component: () => import ('../views/veterinaries/bookings/booking-view.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/booking/:id/cancel',
    component: () => import ('../views/veterinaries/bookings/booking-cancel.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/booking/:id/reschedule',
    component: () => import ('../views/veterinaries/bookings/booking-reschedule.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/veterinaries/:id/view-booking',
    component: () => import ('../views/veterinaries/view-booking.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/veterinaries/:id/reschedule-booking',
    component: () => import ('../views/veterinaries/reschedule-booking.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/veterinaries/booking-home',
    component: () => import ('../views/backend/veterinaries/booking-home.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/veterinaries/accounts',
    component: () => import ('../views/backend/veterinaries/accounts.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/veterinaries/:id/view-account',
    component: () => import ('../views/backend/veterinaries/view-account.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/veterinaries/bookings',
    component: () => import ('../views/backend/veterinaries/bookings.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/veterinaries/:id/view-booking',
    component: () => import ('../views/backend/veterinaries/view-booking.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/vendor/veterinary/bookings',
    component: () => import ('../views/frontend/vendor/veterinaries/bookings.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/vendor/veterinary/profile',
    component: () => import ('../views/frontend/vendor/veterinaries/view-account.vue'),
    meta: {
      requiresAuth: true
    }
  }

];

export default routes;
