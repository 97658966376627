import AuthService from '@/modules/auth/authService';
import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

// Routes
import bookingRoute from './veterinaries';
import breedingRoute from './breeding';
import backendAuthRoute from './backendAuth';
import vendorAuthRoute from './vendor/auth';
import servicesRoute from './services';
import vendorRoute from './vendor';
import backendVendorsRoute from './backend/vendors';


import toolsRoute from './tools/tools';


/** @ignore */
const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    redirect: '/app/home'
  },

  {
    path: '/app/sign-in',
    name: 'sign-in',
    component: () => import ('../views/auth/sign-in.vue'),
    meta: {
      isAuth: true
    }
  },

  {
    path: '/app/sign-up',
    name: 'sign-up',
    component: () => import ('../views/auth/sign-up.vue'),
    meta: {
      isAuth: true
    }
  },
  {
    path: '/app/forgot-password-reset',
    name: 'forgot-reset',
    component: () => import ('../views/auth/forgot-password-reset.vue'),
    meta: {
      isAuth: true
    }
  },

  {
    path: '/app/home',
    name: 'home',
    component: () => import ('../views/home.vue'),
    meta: {
      requiresAuth: true
    }
  },


  {
    path: '/app/activities',
    component: () => import ('../views/activities.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/notifications',
    component: () => import ('../views/notifications.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/account',
    component: () => import ('../views/account.vue'),
    name: 'account',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/manage-address',
    component: () => import ('../views/manage-address.vue'),
    name: 'manage-address',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/manage-address/:id',
    component: () => import ('../views/manage-address.vue'),
    name: 'manage-address-edit',
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/pets',
    component: () => import ('../views/pets.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/view-pet/:id',
    component: () => import ('../views/view-pet.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/add-pet-history/:id',
    component: () => import ('../views/add-pet-history.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/pet-history/:id',
    component: () => import ('../views/edit-pet-history.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/connections',
    component: () => import ('../views/connections.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/support',
    component: () => import ('../views/support.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/support/new-ticket',
    component: () => import ('../views/support-new-ticket.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/support/ticket-success',
    component: () => import ('../views/support-ticket-success.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/support/view-ticket/:id',
    component: () => import ('../views/support-view-ticket.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/shop',
    component: () => import ('../views/shop/shop-items.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/shop/:id',
    component: () => import ('../views/shop/shop-item.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/shop/cart',
    component: () => import ('../views/shop/shop-cart.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/shop/checkout',
    component: () => import ('../views/shop/shop-checkout.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/memberships',
    component: () => import ('../views/memberships.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/app/settings',
    component: () => import ('../views/settings.vue'),
    meta: {
      requiresAuth: true
    }
  },

  /**
   *  BACKEND PAGES
  */
  {
    path: '/backend/sign-in',
    component: () => import ('../views/backend/auth/sign-in.vue'),
    meta: {
      isAuth: true
    }
  },

  {
    path: '/backend/dashboard',
    component: () => import ('../views/backend/dashboard.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/faq-manager',
    component: () => import ('../views/backend/faq-manager.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/memberships',
    component: () => import ('../views/backend/memberships.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/monetization',
    component: () => import ('../views/backend/monetization.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/permissions-system',
    component: () => import ('../views/backend/permissions-system.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/pets',
    component: () => import ('../views/backend/pets.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/services',
    component: () => import ('../views/backend/services.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/settings',
    component: () => import ('../views/backend/settings.vue'),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: '',
        redirect: '/backend/settings/fees',
      },
      {
        path: 'fees',
        component: () => import ('../views/backend/settings/fees.vue'),
      },
      {
        path: 'services',
        component: () => import ('../views/backend/settings/services.vue'),
      },
      {
        path: 'shop',
        component: () => import ('../views/backend/settings/shop.vue'),
      },
    ]
  },

  {
    path: '/backend/shop',
    component: () => import ('../views/backend/shop.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/shop/items',
    component: () => import ('../views/backend/shop/shop-items.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/shop/items/:id',
    component: () => import ('../views/backend/shop/shop-item.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/shop/items/add',
    component: () => import ('../views/backend/shop/shop-item.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/staff-manager',
    component: () => import ('../views/backend/staffManager/staff-manager.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/staff-manager/:id',
    component: () => import ('../views/backend/staffManager/staff-view.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/support/tickets',
    component: () => import ('../views/backend/support-tickets.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/support/ticket/:id',
    component: () => import ('../views/backend/support-view-ticket.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/user-manager',
    component: () => import ('../views/backend/userManager/user-manager.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/view-staff',
    component: () => import ('../views/backend/view-staff.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/user-manager/:id',
    component: () => import ('../views/backend/userManager/view-user.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/verifications',
    component: () => import ('../views/backend/verifications/verification-overview.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/verifications/:category',
    component: () => import ('../views/backend/verifications/verification-manager.vue'),
    meta: {
      requiresAuth: true
    }
  },

  {
    path: '/backend/verifications/:category/:id',
    component: () => import ('../views/backend/verifications/verification-view.vue'),
    meta: {
      requiresAuth: true
    }
  },

];


routes.push(...bookingRoute);
routes.push(...breedingRoute);
routes.push(...backendAuthRoute);
routes.push(...vendorAuthRoute);
routes.push(...servicesRoute);
routes.push(...vendorRoute);
routes.push(...backendVendorsRoute);

// Tools routes
routes.push(...toolsRoute);

// Catch all if no route exists
routes.push({
  path: '/backend/:pathMatch(.*)*',
  redirect: '/backend/dashboard',
});
routes.push({
  path: '/:pathMatch(.*)*',
  redirect: '/app/home',
});


/** @ignore */
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( async (to) => {
  const route = await AuthService().then(user => {
    let redirect:boolean|object = true;

    // If not logged in and auth required
    redirect = !user && to.meta.requiresAuth? { name: 'sign-in' }: redirect;
    // If logged in and is auth page
    redirect = user && to.meta.isAuth? { name: 'home' }: redirect;

    return redirect;
  });


  return route;
});

export default router
