import { RouteRecordRaw } from 'vue-router';


/** @ignore */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/app/breeding',
    name: 'breeding',
    component: () => import ('../views/services/breeding/breeding-select.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/app/breeding/:id',
    component: () => import ('../views/services/breeding/breeding.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/app/breeding/:id/chat',
    component: () => import ('../views/services/breeding/breeding-chat.vue'),
    meta: {
      requiresAuth: true
    }
  },
];

export default routes;
