import { RouteRecordRaw } from 'vue-router';


/** @ignore */
const routes: Array<RouteRecordRaw> = [
  {
    path: '/app/vendor/sign-up',
    name: 'vendor-sign-up',
    component: () => import ('../../views/frontend/vendor/auth/sign-up.vue'),
    meta: {
      requiresAuth: true,
      isVendorAuth: true,
    }
  },
];

export default routes;
