import { RouteRecordRaw } from 'vue-router';


/** @ignore */
const routes: Array<RouteRecordRaw> = [

  {
    path: '/app/vendor/veterinary',
    name: 'vendor-veterinary',
    component: () => import ('../../views/vendor/veterinary/veterinary-profile.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/veterinary/reports',
    component: () => import ('../../views/vendor/veterinary/veterinary-reports.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/veterinary/calendar',
    component: () => import ('../../views/vendor/veterinary/veterinary-calendar.vue'),
    meta: {
      requiresAuth: true,
    }
  },

];

export default routes;
