import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

// Firebase configuration
/** @ignore */
const firebaseConfig = {
  apiKey: "AIzaSyB-uvjYTqD-P4BOpQvMtpAA3LOymO0UcLc",
  authDomain: "pet-project-d513a.firebaseapp.com",
  projectId: "pet-project-d513a",
  storageBucket: "pet-project-d513a.appspot.com",
  messagingSenderId: "807090811581",
  appId: "1:807090811581:web:df2cef526123e07428bdd4"
};

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
// import { nullLiteral } from '@babel/types';

/* Theme main app css */
import './theme/app.css';

// Initialize Firebase
/** @ignore */
const fireApp = initializeApp(firebaseConfig);

/** @ignore */
getFirestore(fireApp);
getStorage(fireApp);

/** @ignore */
const app = createApp(App)
  .use(IonicVue, {
    swipeBackEnabled: false
  })
  .use(router);

router.isReady().then(() => {
  app.mount('#app');
});
