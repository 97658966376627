import { RouteRecordRaw } from 'vue-router';
import veterinaryRoute from './vendor/veterinary';
import supplierRoute from './vendor/supplier';


/** @ignore */
const routes: Array<RouteRecordRaw> = [

  {
    path: '/app/vendor',
    component: () => import ('../views/vendor/vendor-overview.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/:category',
    component: () => import ('../views/vendor/vendor-profile.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/:category/verification',
    component: () => import ('../views/vendor/vendor-verification.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/:category/reports',
    component: () => import ('../views/vendor/vendor-reports.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/bookings',
    component: () => import ('../views/vendor/bookings/booking-manager.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/:category/bookings/:booking_id',
    component: () => import ('../views/vendor/bookings/booking-view.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/:category/bookings/:booking_id/reschedule',
    component: () => import ('../views/vendor/bookings/booking-reschedule.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/:category/bookings/:booking_id/cancel',
    component: () => import ('../views/vendor/bookings/booking-cancel.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/:category/bookings/:booking_id/refund',
    component: () => import ('../views/vendor/bookings/booking-refund.vue'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/app/vendor/:category/bookings/:booking_id/success',
    component: () => import ('../views/vendor/bookings/booking-success.vue'),
    meta: {
      requiresAuth: true,
    }
  },

];

routes.push(...veterinaryRoute);
routes.push(...supplierRoute);

export default routes;
