import { RouteRecordRaw } from 'vue-router';


/** @ignore */
const routes: Array<RouteRecordRaw> = [
  {
    
    path: '/tools/seed',
    component: () => import ('../../views/tools/seed.vue'),
    meta: {
      requiresAuth: true,
    }
  },
];

export default routes;
