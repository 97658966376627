
import { IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu,
         IonMenuToggle, IonRouterOutlet, IonSplitPane, IonAvatar } from '@ionic/vue';
import { defineComponent, ref, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { logInOutline, archiveOutline, archiveSharp, bookmarkOutline, bookmarkSharp, heartOutline, heartSharp, mailOutline, mailSharp, paperPlaneOutline, paperPlaneSharp, trashOutline, trashSharp, warningOutline, warningSharp } from 'ionicons/icons';
import { getAuth, onAuthStateChanged, signOut } from '@firebase/auth';
import UserProfile from './database/interfaces/userProfile';
import Database from './modules/database/database';
import AuthService from './modules/auth/authService';

export default defineComponent({
  name: 'App',
  components: {
    IonApp, IonContent, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonMenu, IonMenuToggle,IonRouterOutlet, IonSplitPane,IonAvatar
  },
  setup() {
    const router = useRouter(),
          selectedIndex = ref(0),
          appPages = [
            {
              title: 'Home',
              url: '/app/home',
              iosIcon: logInOutline,
              mdIcon: logInOutline
            },

            {
              title: 'Membership',
              url: '/app/membership',
              iosIcon: logInOutline,
              mdIcon: logInOutline
            },

            {
              title: 'Profile',
              url: '/app/profile',
              iosIcon: logInOutline,
              mdIcon: logInOutline
            },

            {
              title: 'Pets',
              url: '/app/pets',
              iosIcon: logInOutline,
              mdIcon: logInOutline
            },

            {
              title: 'Settings',
              url: '/app/settings',
              iosIcon: logInOutline,
              mdIcon: logInOutline
            },

            {
              title: 'Support',
              url: '/app/support',
              iosIcon: logInOutline,
              mdIcon: logInOutline
            },
            
            {
              title: 'Sign In',
              url: '/app/sign-in',
              iosIcon: logInOutline,
              mdIcon: logInOutline,
              unAuthed: true
            },

            {
              title: 'Sign Out',
              url: '/app/sign-out',
              iosIcon: logInOutline,
              mdIcon: logInOutline
            },
          ],
          auth = getAuth(),
          loggedIn = ref(false),
          userEmail = ref<string|null>(''),
          userProfile = ref<UserProfile>({}),
          profileDb = new Database('userProfiles');
    
    const path = window.location.pathname.split('folder/')[1];
    if (path !== undefined) {
      selectedIndex.value = appPages.findIndex(page => page.title.toLowerCase() === path.toLowerCase());
    }
    
    const route = useRoute(),
          isAuthRoute = computed(() => route.fullPath === '/app/sign-in' ||
                                       route.fullPath === '/app/sign-up' ? true: false);
    
    const routeScope = computed(() => {
      let routeScopeExplode = route.fullPath.split("/"),
          routeScope = routeScopeExplode[1] === "app"?
            routeScopeExplode[2] === "vendor"?
              routeScopeExplode[2]: routeScopeExplode[1]:
            routeScopeExplode[1];

      return routeScope;
    });

    onAuthStateChanged(auth, (user) => {
      if(user === null) {
        return false;
      }

      loggedIn.value = true;
      userEmail.value = user.email;

      profileDb.getDocument(user.uid).then(response => {
        if(response.size > 0) {
          userProfile.value = response.docs[0].data();
        }
      });
      
    });

    function signMeOut() {
      signOut(auth).then(() => {
        AuthService().then(() => {
          router.push({ name: 'sign-in' });
        });
      });
    }

    return { 
      signMeOut,

      logInOutline,
      selectedIndex,
      archiveOutline, 
      archiveSharp, 
      bookmarkOutline, 
      bookmarkSharp, 
      heartOutline, 
      heartSharp, 
      mailOutline, 
      mailSharp, 
      paperPlaneOutline, 
      paperPlaneSharp, 
      trashOutline, 
      trashSharp, 
      warningOutline, 
      warningSharp,
      isSelected: (url: string) => url === route.path ? 'selected' : '',
      isAuth: isAuthRoute,
      loggedIn,
      userEmail,
      userProfile,
      routeScope
    }
  }
});
