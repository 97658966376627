import { getAuth, onAuthStateChanged, User } from "firebase/auth";

/** @ignore */
let _user:User|null = null;

/**
 * Authentication service method.
 * 
 * The method will return the logged in user object or null if no one is logged in and will also update the object if the state changes.
 *
 * @export
 * @return {*}  {(Promise<User|null>)}
 */
export default function AuthService(): Promise<User|null> {
  return new Promise((resolve) => {
    if(_user !== null) {
      resolve(_user);
    }

    onAuthStateChanged(getAuth(), user => {
      _user = user;
      resolve(_user);
    });
  });
}
